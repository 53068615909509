body {
  background: radial-gradient(
    ellipse farthest-corner at center top,
    #012619,
    #000
  );
  height: 100vh;
  width: 100vw;
  font-family: "Comfortaa", sans-serif;
}


.App {
  font-family: sans-serif;
  text-align: center;
  color: white;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  font-family: "Comfortaa", sans-serif;
}

.counter-container {
  display: flex;
  flex-direction: column;
  padding-top: 1rem;
  font-family: "Comfortaa", sans-serif;
}

.counter-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}


.button-container {
  display: flex;
  justify-content: center;
  padding-top: 2rem;
  width: 100%;
  gap: 0.5rem;
}

.custom-tooltip {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-color: white;
  height: auto;
  width: 150px;
  padding: 5px 10px;
}

.custom-tooltip .label {
  padding: 4px 0;
  margin: 0;
  color: black;
  font-size: 18px;
  font-weight: bold;
}

.custom-tooltip .occupancy-row {
  display: flex;
  align-items: center;
  width: 100%;
  margin: 2px 0;
}

.custom-tooltip .inline-label {
  color: black;
  font-size: 12px;
  font-weight: bold;
  margin-bottom: 0;
  margin-top: 0;
  margin-right: 5px;
}

.occupancy-container {
  display: flex;
  align-items: center;
  font-size: 18px;
  height: 18px !important;
  line-height: 18px !important;
  padding: 0 !important;
  margin: 0 !important;
  overflow: hidden;
}